import Chat from 'components/Chat';
import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect} from 'react';
import BettingImg from 'assets/images/betting-start.png';
import GameImg1 from 'assets/images/game1.png';
import GameImg2 from 'assets/images/game2.png';
import GameImg3 from 'assets/images/game3.png';
import GameImg4 from 'assets/images/game4.png';
import appService from 'store/appService';
import HeaderImg from 'assets/images/status-bar.png';
import classNames from 'classnames';

const BettingStart: FunctionComponent = function BettingStart() {
	const {currentPage, setCurrentPage, isHintStartActive, toggleIsHintStartActive} = appService;

	const gameClickHandler = () => {
		setCurrentPage(2);
	};

	const gameClasses = classNames('betting-start__btn', {
		'betting-start__btn--active': isHintStartActive,
	});

	const onClickDocumentHandler = (event: any) => {
		const eventTarget = event.target;
		if (!eventTarget.classList.contains('betting-start__item--hint')) {
			toggleIsHintStartActive(true);
			setTimeout(() => {
				toggleIsHintStartActive(false);
			}, 3000);
		}
	};

	useEffect(() => {
		return () => {
			toggleIsHintStartActive(false);
		};
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<section className='betting-start' onClick={onClickDocumentHandler}>
			<img src={HeaderImg} alt='' className='betting-start__header-img' />
			<img src={BettingImg} alt='' className='betting-start__img' />

			<ul className='betting-start__list'>
				<li className='betting-start__item betting-start__item--hint'>
					<button type='button' className={gameClasses} onClick={gameClickHandler}>
						<img src={GameImg1} alt='' />
					</button>
				</li>
				<li className='betting-start__item'>
					<img src={GameImg2} alt='' />
				</li>
				<li className='betting-start__item'>
					<img src={GameImg3} alt='' />
				</li>
				<li className='betting-start__item'>
					<img src={GameImg4} alt='' />
				</li>
			</ul>
			{/* <Chat /> */}
		</section>
	);
};

export default observer(BettingStart);
