import {observer} from 'mobx-react-lite';
import {FunctionComponent, useState} from 'react';
import Modal from 'react-modal';
import {ReactComponent as IcoClose} from 'assets/svg/close.svg';
import appService from 'store/appService';
import classNames from 'classnames';

export const MODAL_STYLE_BETTING_V2 = {
	overlay: {
		display: 'flex',
		alignItems: 'flex-end',
		backgroundColor: 'rgba(0, 0, 0, 0)',
		zIndex: 1000,
		top: '20%',
	},
};

type BetInfo = {
	positionId: string | number;
	factorValue: string | number;
	eventId: string | number;
	eventTitle: string;
	eventDate: string;
	outcomeId: string | number;
	outcomeGroupTitle: string;
	outcomeTitle: string;
	blockTitle: string;
	additionalValue?: string | number | null;
	sportTypeTitle?: string;
	sportTypeId?: number;
	status?: number;
	iconUrl?: string;
};

enum BetTypes {
	SINGLE = 'SINGLE',
	EXPRESS = 'EXPRESS',
}

enum BetStatus {
	NULL = 'NULL',
	WIN = 'WIN',
	LOSE = 'LOSE',
}

enum BetEventStatus {
	'Pending' = 1,
	'Won',
	'Lost',
	'Return',
	'Lost | Return',
	'Won | Return',
	'Canceled',
	'Initial Return',
	'Pending | Initial Return',
	'Won | Initial Return',
}

export {BetTypes, BetStatus, BetEventStatus};

const CartModal: FunctionComponent = function CartModal() {
	const {isCartModalVisible, toggleIsCartModalVisible, copiedBet, toggleIsToastVisible, setAmount} =
		appService;

	const [betValue, setBetValue] = useState(500);

	const betEventStatusClasses = (eventStatus?: number) => {
		return classNames('bet__event', {
			'bet__event--lost': eventStatus === BetEventStatus.Lost,
			'bet__event--lost-return': eventStatus === BetEventStatus['Lost | Return'],
			'bet__event--won': eventStatus === BetEventStatus.Won,
			'bet__event--won-return':
				eventStatus === BetEventStatus['Won | Return'] ||
				eventStatus === BetEventStatus['Won | Initial Return'],
			'bet__event--pending': eventStatus === BetEventStatus.Pending,
			'bet__event--canceled': eventStatus === BetEventStatus.Canceled,
			'bet__event--return':
				eventStatus === BetEventStatus.Return || eventStatus === BetEventStatus['Initial Return'],
		});
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {value} = event.target;
		setBetValue(+value);
	};

	const onDownClickHandler = () => {
		betValue > 100 ? setBetValue(betValue - 100) : setBetValue(0);
	};

	const onUpClickHandler = () => {
		setBetValue(betValue + 100);
	};

	const placeBetClickHandler = () => {
		setAmount(copiedBet ? betValue * copiedBet.totalFactor : betValue);
		toggleIsToastVisible(true);
		toggleIsCartModalVisible(false);
	};

	const renderBetInfo = (item: BetInfo, index: number) => (
		<div key={`${index}${item.eventId}`}>
			<div className={betEventStatusClasses(item.status)}>
				<div className='bet__event-line'>
					<div className='bet__event-outcome'>
						<p className='bet__event-outcome-title'>{item.eventTitle}</p>
					</div>

					<div className='bet__event-info'>
						<p className='bet__event-title'>
							{item.outcomeGroupTitle} {item.outcomeTitle} {item.additionalValue} ({item.blockTitle}
							)
						</p>
						<div className='bet__event-outcome-factor'>{item.factorValue}</div>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<Modal
			className='modal modal--cart'
			isOpen={isCartModalVisible}
			parentSelector={() => document.querySelector('.index-intro__betting-app') || document.body}
			ariaHideApp={false}
			style={{
				overlay: MODAL_STYLE_BETTING_V2.overlay,
			}}>
			<div className='modal__head'>
				<div className='modal__title'>Place bet</div>
				<button
					type='button'
					className='modal__close'
					onClick={() => toggleIsCartModalVisible(false)}>
					<IcoClose />
				</button>
			</div>
			<div className='modal__body'>
				<div className='bet__content'>
					<div className='bet__events'>{copiedBet?.positions.map(renderBetInfo)}</div>
				</div>
				<div className='modal__calc'>
					<div className='modal__calc-field'>
						<input
							type='text'
							className='modal__calc-input'
							onChange={onChangeHandler}
							value={betValue}
						/>
					</div>

					<div className='modal__calc-btns'>
						<button type='button' className='modal__calc-btn' onClick={onDownClickHandler}>
							-
						</button>
						<button type='button' className='modal__calc-btn' onClick={onUpClickHandler}>
							+
						</button>
					</div>
				</div>
				<p className='modal__calc-value'>
					Potential Winnings:
					{copiedBet?.totalFactor && <span>{betValue * copiedBet.totalFactor} $</span>}
				</p>
				<button type='button' className='btn' onClick={placeBetClickHandler} disabled={!betValue}>
					Place bet
				</button>
			</div>
		</Modal>
	);
};

export default observer(CartModal);
