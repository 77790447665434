import {observer} from 'mobx-react-lite';
import {FunctionComponent} from 'react';
import appService from 'store/appService';
import BettingStart from './BettingStart';
import BettingList from './BettingList';

const BettingApp: FunctionComponent = function BettingApp() {
	const {currentPage} = appService;

	switch (currentPage) {
		case 1:
			return <BettingStart />;
		case 2:
			return <BettingList />;
		default:
			return <BettingStart />;
	}
};

export default observer(BettingApp);
