import {getRandomInt} from 'helpers';
import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

const Chat: FunctionComponent = function Chat() {
	const [userId, setUserId] = useState<number | null>(null);

	const url =
		process.env.REACT_APP_ENVIRONMENT !== 'dev'
			? `https://chat.watchers.io/?roomId=testcolors&userId=${userId}&apikey=5d588bd7-e1ea-4f3d-a9f8-317e5317e4e9`
			: `https://web.stage.watchers.io/?userId=${userId}&roomId=bettingtest1&apikey=7b682c66-d93b-41dc-906b-082084e85877`;

	useEffect(() => {
		const watchersioStandUserId = localStorage.getItem('watchersioStandUserId');

		if (watchersioStandUserId) {
			setUserId(JSON.parse(watchersioStandUserId));
		} else {
			const id = getRandomInt();
			setUserId(id);
			localStorage.setItem('watchersioStandUserId', JSON.stringify(id));
		}
	}, []);

	return (
		<iframe
			title='Chat'
			className='stand__chat'
			src={`${url}`}
			frameBorder='0'
			allow='clipboard-read; clipboard-write; microphone; web-share;'
			height='100%'
			width='100%'
		/>
	);
};

export default observer(Chat);
