import {observer} from 'mobx-react-lite';
import BettingApp from 'pages/BettingApp';
import {FunctionComponent} from 'react';
// import Header from 'components/Header';
import IPhoneImg from 'assets/images/iphone.png';
import CookiePopup from 'components/CookiePopup';

const Main: FunctionComponent = function Main() {
	return (
		<>
			{/* <Header /> */}
			<section className='index-intro'>
				<div className='index-intro__info'>
					<h2 className='common__h2-heading index-intro__heading'>
						Make your&nbsp;
						<br />
						<span className='common__heading-part common__heading-part_yellow'>
							betting platform{' '}
						</span>
						social
					</h2>
					<p className='index-intro__description'>
						Allow players to discuss, share&nbsp;and copy bets directly on&nbsp;your platform
					</p>
				</div>
				<div className='index-intro__main-picture'>
					<img src={IPhoneImg} alt='' className='index-intro__main-picture-img' />
					<div className='index-intro__betting-app'>
						<BettingApp />
					</div>
				</div>
			</section>
			<CookiePopup />
		</>
	);
};

export default observer(Main);
