import {action, makeObservable, observable} from 'mobx';

class AppService {
	@observable
	public currentPage = 1;

	@observable
	public isCartModalVisible = false;

	@observable
	public copiedBet: any = null;

	@observable
	public isHintStartActive = false;

	@observable
	public isHintToggleActive = false;

	@observable
	public isToastVisible = false;

	@observable
	public betAmount = 0;

	@action
	public setCurrentPage = (value: number) => {
		this.currentPage = value;
	};

	@action
	public toggleIsCartModalVisible = (value: boolean) => {
		this.isCartModalVisible = value;
	};

	@action
	public setCopiedBet = (value: any) => {
		this.copiedBet = value;
	};

	@action
	public toggleIsHintStartActive = (value: boolean) => {
		this.isHintStartActive = value;
	};

	@action
	public toggleIsHintToggleActive = (value: boolean) => {
		this.isHintToggleActive = value;
	};

	@action
	public toggleIsToastVisible = (value: boolean) => {
		this.isToastVisible = value;
	};

	@action
	public setAmount = (value: number) => {
		this.betAmount = value;
	};

	constructor() {
		makeObservable(this);
	}
}

export default new AppService();
