import {useCallback, useEffect, useState} from 'react';
import {Switch, Route} from 'react-router-dom';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {isMobile} from 'react-device-detect';

import './app.scss';
import Main from 'pages/main/Main';
import appService from 'store/appService';

const App = function App() {
	const {isCartModalVisible, toggleIsCartModalVisible, setCopiedBet} = appService;

	const onMessageHandler = (e: MessageEvent) => {
		const postMessage = e.data;

		if (postMessage.type === 'betRepeat' && postMessage.body.action === 'share') {
			const {bet} = postMessage.body.data;
			setCopiedBet(bet);
			toggleIsCartModalVisible(true);
		}
	};

	useEffect(() => {
		window.addEventListener('message', onMessageHandler);

		return () => {
			window.removeEventListener('message', onMessageHandler);
		};
	}, []);

	return (
		<Switch>
			<Route exact path='/'>
				<Main />
			</Route>
		</Switch>
	);
};

export default observer(App);
