/* eslint-disable jsx-a11y/label-has-associated-control */
import {observer} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';
import BettingHeaderImg from 'assets/images/betting-list.png';
import BettingContentImg from 'assets/images/betting-list-content.png';
import HeaderImg from 'assets/images/status-bar.png';
import LionImg from 'assets/images/lion.png';
import appService from 'store/appService';
import Chat from 'components/Chat';

import {ReactComponent as IcoArrow} from 'assets/svg/arrow.svg';
import {ReactComponent as IcoSuccess} from 'assets/svg/ico-success.svg';
import {ReactComponent as IcoCart} from 'assets/svg/cart.svg';

import CartModal from 'components/CartModal';
import classNames from 'classnames';

const BettingList: FunctionComponent = function BettingList() {
	const {
		currentPage,
		setCurrentPage,
		toggleIsHintToggleActive,
		isHintToggleActive,
		isToastVisible,
		toggleIsToastVisible,
		copiedBet,
		betAmount,
	} = appService;
	const [isLiveChat, setIsLiveChat] = useState<boolean>(false);

	const toggleClasses = classNames('betting-list__toggle', {
		'betting-list__toggle--active': isHintToggleActive,
	});

	const chatClasses = classNames('betting-list__content-chat', {
		'betting-list__content-chat--visible': isLiveChat,
	});

	const bettingAppClasses = classNames('betting-list__content-app', {
		'betting-list__content-app--visible': !isLiveChat,
	});

	const backClickHandler = () => {
		setCurrentPage(1);
	};

	const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {checked} = event.target;
		setIsLiveChat(checked);
		toggleIsHintToggleActive(false);
	};

	const onClickDocumentHandler = (event: any) => {
		const eventTarget = event.target;
		if (!eventTarget.classList.contains('betting-list__toggle') && !isLiveChat) {
			toggleIsHintToggleActive(true);
			setTimeout(() => {
				toggleIsHintToggleActive(false);
			}, 3000);
		}
	};

	useEffect(() => {
		!isLiveChat && toggleIsToastVisible(false);
	}, [isLiveChat]);

	useEffect(() => {
		if (isToastVisible) {
			setTimeout(() => {
				toggleIsToastVisible(false);
			}, 3000);
		}
	}, [isToastVisible]);

	useEffect(() => {
		return () => {
			toggleIsHintToggleActive(false);
			setIsLiveChat(false);
		};
	}, []);

	return (
		// eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
		<section className='betting-list' onClick={onClickDocumentHandler}>
			<div className='betting-list__header'>
				<img src={HeaderImg} alt='' className='betting-list__header-img' />
				<img src={BettingHeaderImg} alt='' className='betting-list__widget-img' />
				<img src={LionImg} alt='' className='betting-list__lion-img' />
				<div className='betting-list__header-btns'>
					<button type='button' onClick={backClickHandler} className='betting-list__back-btn'>
						<IcoArrow />
					</button>
					<button type='button' className='betting-list__cart-btn'>
						<IcoCart />
					</button>
				</div>

				<div className={toggleClasses}>
					<input
						type='checkbox'
						name='toggle'
						id='placeBet'
						className='betting-list__checkbox-input'
						onChange={onChangeHandler}
					/>
					<label htmlFor='placeBet' className='betting-list__checkbox-label'>
						<div className='betting-list__checkbox-label-content'>
							<span>Place bet</span>
							<span>Live chat</span>
						</div>
					</label>
				</div>
			</div>

			<div className='betting-list__content'>
				<div className={bettingAppClasses}>
					<img src={BettingContentImg} alt='' className='betting-list__app-img' />
				</div>

				<div className={chatClasses}>
					<Chat />
				</div>
			</div>

			<div
				className={
					isToastVisible
						? 'betting-list__toast betting-list__toast--visible'
						: 'betting-list__toast'
				}>
				<div className='betting-list__toast-info'>
					<IcoSuccess className='betting-list__toast-ico' />
					<b>Bet was placed: </b>
					<p> {copiedBet?.positions[0].eventTitle}</p>
				</div>
				<div className='betting-list__toast-result'>
					<p className='betting-list__toast-result-amount'>
						Amount: <b>$ {betAmount}</b>
					</p>
					<p className='betting-list__toast-result-total'>
						Total: <b>{copiedBet?.totalFactor}</b>
					</p>
				</div>
			</div>

			<CartModal />
		</section>
	);
};

export default observer(BettingList);
