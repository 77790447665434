import classNames from 'classnames';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {FunctionComponent, useEffect, useState} from 'react';

const CookiePopup: FunctionComponent = function CookiePopup() {
	const [isCookiePopupVisible, setIsCookiePopupVisible] = useState(false);

	const cookiePopupClasses = classNames('cookie-popup', {
		'cookie-popup--visible': isCookiePopupVisible,
	});

	const onClickAcceptHandler = () => {
		localStorage.setItem('watchersioAcceptCookie', 'true');
		setIsCookiePopupVisible(false);
	};

	useEffect(() => {
		const acceptCookie = localStorage.getItem('watchersioAcceptCookie');
		if (!acceptCookie) setIsCookiePopupVisible(true);
	}, []);

	return (
		<div className={cookiePopupClasses}>
			<div className='cookie-popup__content'>
				<div className='cookie-popup__text'>
					<p>
						We use cookies to enhance your experience on our website. Cookies help us personalize
						content, provide social media features, and analyze our traffic. By continuing to use
						our site, you agree to our use of cookies.
					</p>
					<p>
						Learn more in our
						<a
							href='https://legal.watchers.io/privacy_policy.pdf'
							target='_blank'
							className='cookie-popup__link'
							rel='noreferrer'>
							Privacy Policy
						</a>
					</p>
				</div>
				<div className='cookie-popup__btns'>
					<button
						type='button'
						className='common__button common__button_small common__button_brand cookie-popup__btn'
						onClick={onClickAcceptHandler}>
						Accept
					</button>
					<button
						type='button'
						className='common__button common__button_small common__button_grey cookie-popup__btn'
						onClick={onClickAcceptHandler}>
						Decline
					</button>
				</div>
			</div>
		</div>
	);
};
export default observer(CookiePopup);
